import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/": [~14],
		"/about-us": [~15],
		"/auth/account": [16,[2]],
		"/auth/account/courses": [17,[2]],
		"/auth/account/courses/[id]": [18,[3]],
		"/auth/account/courses/[id]/[section]": [~19,[3]],
		"/auth/account/courses/[id]/[section]/[lesson]": [~20,[3]],
		"/auth/account/membership": [~21,[2]],
		"/auth/account/orders": [~22,[2]],
		"/auth/account/tutorials": [~23,[2]],
		"/auth/account/user-info": [~24,[2]],
		"/auth/admin": [25,[4]],
		"/auth/admin/courses": [26,[4]],
		"/auth/admin/courses/[id]": [~27,[4]],
		"/auth/admin/members": [~28,[4]],
		"/auth/admin/members/[id]": [~29,[4]],
		"/auth/admin/products": [~30,[4]],
		"/auth/admin/tutorials": [31,[4]],
		"/auth/admin/tutorials/[id]": [~32,[4]],
		"/auth/login": [~33],
		"/auth/logout": [~34],
		"/auth/register": [~35],
		"/blog": [~36],
		"/blog/[slug]": [~37],
		"/collections": [~38,[5],[6]],
		"/collections/bundles": [~41,[5],[6]],
		"/collections/bundles/[handle]": [~42,[5,8],[6]],
		"/collections/courses": [~43,[5],[6]],
		"/collections/courses/advanced-bare-microblading": [~44,[5],[6]],
		"/collections/courses/building-a-brow": [~45,[5],[6]],
		"/collections/courses/foundations-of-fluff": [~46,[5],[6]],
		"/collections/courses/lips-by-mikaila": [~47,[5],[6]],
		"/collections/courses/nano-machine-hair-strokes": [~48,[5],[6]],
		"/collections/trainings/[handle]": [~49,[5],[6]],
		"/collections/tutorials": [~50,[5,9],[6]],
		"/collections/tutorials/[handle]": [~51,[5,9],[6]],
		"/collections/[handle]": [~39,[5],[6]],
		"/collections/[handle]/[handle]": [~40,[5,7],[6]],
		"/contact": [~52],
		"/education": [53,[10]],
		"/education/membership": [~54,[10]],
		"/education/membership/join": [~55,[10]],
		"/education/membership/join/cancel": [56,[10]],
		"/education/membership/join/success": [~57,[10]],
		"/education/trainings": [~58,[10]],
		"/education/trainings/advanced-training": [~60,[10,11]],
		"/education/trainings/beginner-training": [~61,[10,12]],
		"/education/trainings/[handle]": [~59,[10]],
		"/faqs": [~62],
		"/faqs/client-faqs": [~63],
		"/faqs/product-faqs": [~64],
		"/landing": [~65,[],[13]],
		"/landing/best-microblading-aftercare": [~66,[],[13]],
		"/policy/return": [~67],
		"/policy/shipping": [~68],
		"/pro-team": [~69]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';